import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './Root.store'
import {
  PdfTab,
  TransactionPostRequest,
  TransactionResponseParsed,
} from '@/pages/CreateTransactionPage/types'
import { initObjV2 } from '@/pages/CreateTransactionPage/constants'
import { SelectOption } from '@/types/generic'
import { axiosInstance } from '@/axios/AxiosInstance'
import { format } from 'date-fns'

const resultsMock: TransactionResponseParsed[] = [
  {
    transactionId: 'aee5cda3-98ae-4c34-abc7-0c514abe691f',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `https://web-api.dev.identity.signicat.global/onboarding-dashboard/transactions/23 45678-12374-cdb7292-sf881238?tenantId=dev.pink`,
  },
  {
    transactionId: 'aee5cda3-98ae-4c34-abc7-0c514abe691f',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `https://web-api.dev.identity.signicat.global/onboarding-dashboard/transactions/23 45678-12374-cdb7292-sf881238?tenantId=dev.pink`,
  },
  {
    transactionId: 'aee5cda3-98ae-4c34-abc7-0c514abe691f',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `https://web-api.dev.identity.signicat.global/onboarding-dashboard/transactions/23 45678-12374-cdb7292-sf881238?tenantId=dev.pink`,
  },
  {
    transactionId: 'aee5cda3-98ae-4c34-abc7-0c514abe691f',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `https://web-api.dev.identity.signicat.global/onboarding-dashboard/transactions/23 45678-12374-cdb7292-sf881238?tenantId=dev.pink`,
  },
  {
    transactionId: 'aee5cda3-98ae-4c34-abc7-0c514abe691f',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `https://web-api.dev.identity.signicat.global/onboarding-dashboard/transactions/23 45678-12374-cdb7292-sf881238?tenantId=dev.pink`,
  },
  {
    transactionId: '3e5cfa3-98ae-4c34-absd-0c514absssdf',
    proceedToken: '732258c8-627c-498e-a194-597d04eb9035',
    proceedUrl:
      'https://web-api.dev-05-eu.identity.signicat.global/onboarding/proceed?proceedToken=732258c8-627c-498e-a194-597d04eb9035',
    status: '200 OK',
    date: format(new Date(), 'HH:mm, dd.MM.yyyy'),
    apiUrl: `${axiosInstance.defaults.baseURL}/transactions/transactionId?tenantId=tenantId`,
  },
]

export class CreateTransactionStore {
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  rootStore: RootStore

  // statuses
  isLoading = false

  // input based body
  configuration = 'configuration'

  // editor based body
  jsonObject: TransactionPostRequest = initObjV2

  // other state
  selectedPdfTab: PdfTab = 'select'
  mockPdf: SelectOption = { value: 'mockPdf', label: 'Mock PDF' }
  filePdf: File = undefined
  base64Pdf = ''

  // output
  results: TransactionResponseParsed[] = resultsMock

  @action.bound setJsonObject = (jsonObject: TransactionPostRequest): void => {
    this.jsonObject = jsonObject
  }

  @action.bound setPdfTab = (tab: PdfTab): void => {
    this.selectedPdfTab = tab
  }

  @action.bound handlePdfChange = (file: File | SelectOption | string): void => {
    if (file instanceof File) {
      this.filePdf = file
    } else if (typeof file === 'string') {
      this.base64Pdf = file
    } else {
      this.mockPdf = file
    }
  }
}
