import { FC, useContext, useState } from 'react'
import { JSONEditorContainer, SectionTitle } from '../CreateTransactionPage.styles'
import { JSONEditor } from '@/components/JSONEditor/JSONEditor'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { ParserPill } from './ParserPill'
import { JsonError } from '../types'

export const TransactionJSONBodyEditor: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { jsonObject } = store.CreateTransactionState

  const [currentObjError, setCurrentObjError] = useState<JsonError | null>(null)
  const [currentObjErrorValue, setCurrentObjErrorValue] = useState<boolean>(false)
  const handleLint = (diagnostics: JsonError) => {
    setCurrentObjErrorValue(!!diagnostics?.length)
    setCurrentObjError(diagnostics)
  }

  return (
    <JSONEditorContainer>
      <SectionTitle>
        JSON parser <ParserPill status={currentObjErrorValue ? 'error' : 'valid'} />
      </SectionTitle>
      <JSONEditor
        onLint={handleLint}
        value={JSON.stringify(jsonObject, null, 2)}
        onChange={(e, val) => {
          if (!val) return
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          store.CreateTransactionState.setJsonObject(JSON.parse(val))
        }}
        maxHeight="152px"
        height="100%"
      />
    </JSONEditorContainer>
  )
})
