import { TransactionPostRequest } from './types'

export const initObjV2: TransactionPostRequest = {
  configurationId: '<configurationId>',
  preferredLanguage: '<preferredLanguage>',
  redirectUrl: '<redirectUrl>',
}

export const initObjV3 = (configurationId: string): TransactionPostRequest => {
  if (configurationId === 'demo.addressVerification') {
    return {
      configurationId: '<configurationId>',
      preferredLanguage: '<preferredLanguage>',
      redirectUrl: '<redirectUrl>',
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
      },
    }
  } else {
    return {
      configurationId: '<configurationId>',
      preferredLanguage: '<preferredLanguage>',
      redirectUrl: '<redirectUrl>',
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
        dateOfBirth: '1980-01-01',
        nationalities: ['POL', 'DEU'],
        bankInformation: {
          accountHolder: 'Erika Mustermann',
          iban: 'DE62888888880012345678',
        },
      },
    }
  }
}
