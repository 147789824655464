import { FC, useContext, useRef } from 'react'
import {
  Base64TextArea,
  CreateButton,
  FileInput,
  FileInputContainer,
  PdfLabel,
  SectionTitle,
  Tab,
  TabbedPdfContainer,
  TabContainer,
  UploadButton,
} from '../CreateTransactionPage.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { SelectInput } from '@/components/Generic'

const Input: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { selectedPdfTab, mockPdf, filePdf, base64Pdf } = store.CreateTransactionState

  const fileInputRef = useRef<HTMLInputElement>()

  switch (selectedPdfTab) {
    case 'select':
      return (
        <>
          <PdfLabel>{trans.existingPDFmocks}</PdfLabel>
          <SelectInput options={[{ value: 'mockPdf', label: 'Mock PDF' }]} value={mockPdf} />
        </>
      )
    case 'upload':
      return (
        <FileInputContainer>
          {/* <PdfLabel>{trans.uploadNewPDF}</PdfLabel> */}
          <FileInput
            ref={fileInputRef}
            type="file"
            value={filePdf ? filePdf.name : ''}
            onChange={(e) => store.CreateTransactionState.handlePdfChange(e.target.files[0])}
          />
          <UploadButton
            onClick={() => {
              fileInputRef.current.click()
            }}
          >
            {trans.uploadNewPDF}
          </UploadButton>
        </FileInputContainer>
      )
    case 'base64':
      return (
        <>
          <PdfLabel>{trans.pasteBase64}</PdfLabel>
          <Base64TextArea
            value={base64Pdf}
            onChange={(e) => store.CreateTransactionState.handlePdfChange(e.target.value)}
            placeholder={trans.pastePDFbase64}
            rows={1}
          />
        </>
      )
  }
})

export const TabbedPdfSelector: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { selectedPdfTab } = store.CreateTransactionState

  return (
    <TabbedPdfContainer>
      <SectionTitle>{trans.PDFdocument}</SectionTitle>
      <TabContainer>
        <Tab
          active={selectedPdfTab === 'select'}
          onClick={() => store.CreateTransactionState.setPdfTab('select')}
        >
          {trans.selectMock}
        </Tab>
        <Tab
          active={selectedPdfTab === 'upload'}
          onClick={() => store.CreateTransactionState.setPdfTab('upload')}
        >
          {trans.uploadPDFFile}
        </Tab>
        <Tab
          active={selectedPdfTab === 'base64'}
          onClick={() => store.CreateTransactionState.setPdfTab('base64')}
        >
          {trans.pasteBase64}
        </Tab>
      </TabContainer>

      <Input />
    </TabbedPdfContainer>
  )
})
